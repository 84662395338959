import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targetPath: "",
};

export const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    setTargetPath: (state, action) => {
      state.targetPath = action.payload;
    },
  },
});

export const { setTargetPath } = redirectSlice.actions;

export default redirectSlice.reducer;