import { notification } from "antd";

export default function showCustomNotification({
  status,
  description,
  message,
}) {
  if (status) {
    notification[status]({
      message: message,
      description: description,
    });
  }
}
