import React, { useEffect, createContext } from "react";
import { Switch, Route, BrowserRouter, useHistory, useLocation } from "react-router-dom";
import "./App.scss";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./pages/Home";
import { Modal } from "antd";

const ReachableContext = createContext(null);
const config = {
  title: "Token không hợp lệ!",
  maskClosable: false,
  footer: null,
  content: (
    <>
      <ReachableContext.Consumer>{(name) => `Có lỗi xảy ra vui lòng trở về trang chủ`}</ReachableContext.Consumer>
    </>
  ),
};

const App = () => {
  const [modal, contextHolder] = Modal.useModal();
  const accessToken = Cookies.get("accessToken");
  const fallbackUri = accessToken ? "/dashboard/home" : "/login";
   const history = useHistory();
   const location = useLocation();

  function decodeToken() {
    // Lấy token từ URL
    const queryParams = new URLSearchParams(window.location.search);
    let token = queryParams.get("verifyToken") || localStorage.getItem("verifyToken");;
    if (token) {
      const updateToken = queryParams.get("verifyToken");
      if (updateToken) {
        localStorage.setItem("verifyToken", updateToken);
      }
      const payloadBase64 = token.split(".")[1];
      // Giải mã phần payload từ Base64 URL sang chuỗi JSON
      const payloadJson = JSON.parse(atob(payloadBase64));
      // Cookies.set("decodedToken", JSON.stringify(payloadJson), { expires: 1 }); // expires: 1 ngày
      localStorage.setItem("decodedToken", JSON.stringify(payloadJson));
      queryParams.delete("verifyToken");
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }
    else {
      modal.error(config); 
    }
  }

  useEffect(() => {
     decodeToken();
  }, []);

  return (
   <>
    <Switch>
      {/* <Redirect exact from="/" to={accessToken ? "/dashboard/home" : "/login"} /> */}
      {/* <RouteUnauthenticated path="/login" component={Login} /> */}
      {/* <RouteAuthenticated path="/dashboard/home" component={Home} /> */}
      <Redirect exact from="/" to={"/vemaybay"} />
      <Route exact component={Home} path={"/vemaybay"} />
      <Redirect to={"/vemaybay"} />
    </Switch>
    {contextHolder}
   </>

  );
};

// const RouteAuthenticated = ({ component: Component, path }) => {
//  const accessToken = Cookies.get("accessToken");
//   if (!accessToken) {
//     return <Redirect to="/login" />;
//   }
//   return (
//     <LayoutClient>
//       <Route exact component={Component} path={path} />
//     </LayoutClient>
//   )
// };

const RouteUnauthenticated = ({ component: Component, path }) => {
  // const accessToken = Cookies.get("accessToken");
  // if (accessToken) {
  //   return <Redirect to="/dashboard/home" />;
  // }

  return <Route exact component={Component} path={path} />;
};

export default App;
