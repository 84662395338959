import { LeftCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formatNumberWithDots, getStatusDiv } from "../../helpers/utils";
import $axios from "../../services/axios";
import TicketDetailsDrawer from "../Common/DetailHistory";
import showCustomNotification from "../Common/showCustomNotification";
import dayjs from "dayjs";
const History = ({ airports, onChangeStep }) => {
  const [form] = Form.useForm();
  const orderValue = Form.useWatch("OrderID", form);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [detailTicket, setDetailTicket] = useState({});
  const [listHistory, setListHistory] = useState([]);
  const [filterHistory, setFilterHistory] = useState([]);
  const onFinish = async (values) => {
    setFilterHistory(
      listHistory.filter((item) =>
        (item.order_code || "").toLowerCase().includes(orderValue)
      )
    );
  };

  const getHistory = async () => {
    const verifyToken = localStorage.getItem("verifyToken");
    try {
      const response = await $axios.$get(
        `/history?token=${verifyToken}&order_code=`
      );
      setListHistory(response.data.histories || []);
      setFilterHistory(response.data.histories || []);
    } catch (error) {
      showCustomNotification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };
  useEffect(() => {
    getHistory();
  }, []);
  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full 0  bg-white shadow-lg rounded-lg">
        <img
          className="w-full h-[260px] object-cover bg-step1"
          src="/images/earth.svg"
        />
        <div className="w-[90%] mx-auto absolute top-[7%] left-1/2 transform -translate-x-1/2 -translate-y-4/5 pb-[40px] flex flex-col">
          <div className="flex justify-center items-center">
            <div className="flex flex-col gap-2 items-center w-full">
              <div className="w-full flex items-center justify-between">
                <LeftCircleOutlined
                  onClick={() => onChangeStep(1)}
                  className="text-white text-[32px]"
                />

                <img
                  src={"/images/flight-icon.svg"}
                  alt=""
                  className="w-[57px]"
                  onClick={() => onChangeStep(1)}
                />
                <div className="w-8 h-8"></div>
              </div>
              <h2 className="text-white text-[16px] font-semibold">
                Lịch sử đặt vé
              </h2>
              <div className="w-full px-5 py-6 rounded-[20px] bg-white">
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="horizontal"
                  className="flex items-start gap-2 m-0"
                >
                  <Form.Item name={"OrderID"} className="m-0 flex-1">
                    <Input className="h-10" placeholder="Nhập mã đặt vé" />
                  </Form.Item>
                  <Form.Item className="m-0">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="bg-[#0064D2] h-10 font-bold text-white"
                    >
                      Tìm kiếm
                    </Button>
                  </Form.Item>
                </Form>
                {/* Hotline */}
              </div>

              <div className="w-full">
                <div className="text-center mt-8">
                  <span className="text-[#808080] font-normal">
                    Hotline hỗ trợ khách hàng{" "}
                  </span>
                  <a
                    href="tel:0876100800"
                    className="font-bold text-[#0064D2] text-[16px]"
                  >
                    0876100800
                  </a>
                </div>
                <Button className="mt-3  rounded-[20px]" type="primary" ghost>
                  <p className="font-bold">Vé máy bay</p>
                </Button>
              </div>
              {filterHistory.length === 0 && (
                <h2 className="w-full text-primary font-semibold text-[19px] text-center absolute top-[480px] left-1/2 transform -translate-x-1/2 p-4">
                  Không tìm thấy lịch sử đặt vé, vui lòng tìm kiếm lại
                </h2>
              )}
              {filterHistory.map((item, index) => (
                <div
                  key={index}
                  className="mt-2 w-full p-4 bg-white rounded-[20px] mb-4 "
                  style={{
                    boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Row gutter={8} className="justify-between items-center">
                    <Col className="flex flex-col gap-2">
                      {item?.transaction_fare.map((item1, index) => (
                        <Row key={index} gutter={4} align={"middle"}>
                          <Col>
                            <p className="text-[10px] font-bold text-center">
                              {item1?.start_point || ""}
                            </p>
                            <div className="flex gap-1">
                              <h4 className="text-[9px]">
                                {dayjs(item1?.start_at).format("DD/MM/YYYY")}
                              </h4>
                              <h4 className="text-[9px]">
                                {(
                                  (item1?.start_at || "").split(" ")[1] || ""
                                ).split(":")?.[0] +
                                  ":" +
                                  (
                                    (item1?.start_at || "").split(" ")[1] || ""
                                  ).split(":")?.[1]}
                              </h4>
                            </div>
                          </Col>
                          <Col className="justify-center text-center">
                            <img src="/images/flight-line.svg" alt="" />
                          </Col>
                          <Col>
                            <p className="text-[10px] font-bold text-center">
                              {item1?.end_point || ""}
                            </p>
                            <div className="flex gap-1">
                              <h4 className="text-[9px]">
                                {dayjs(item1?.end_at).format("DD/MM/YYYY")}
                              </h4>
                              <h4 className="d text-[9px]">
                                {(
                                  (item1?.end_at || "").split(" ")[1] || ""
                                ).split(":")?.[0] +
                                  ":" +
                                  (
                                    (item1?.end_at || "").split(" ")[1] || ""
                                  ).split(":")?.[1]}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                    <Col>
                      <div className="flex gap-2 items-center mb-2">
                        <div className="font-bold text-primary text-[10px] ">
                          {formatNumberWithDots(item?.amount)} VND
                        </div>
                      </div>

                      <div className="flex gap-2 items-center justify-center text-center">
                        <span
                          className="text-primary text-[10px]"
                          onClick={() => {
                            setDetailTicket(item);
                            setDrawerVisible(true);
                          }}
                        >
                          Chi tiết <img src="/images/2-down.svg" alt="" />
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-2" />
                  <Row className="justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <img
                        className="w-auto h-4"
                        src={`https://abtrip.vn/images/airlines/${item?.transaction_booking?.[0]?.airline.toLowerCase()}.gif`}
                        alt=""
                      />
                      <div>
                        <p className="text-[10px] text-[#808089] mt-1">
                          Mã đơn hàng: {item?.order_code || ""}
                        </p>
                        <p className="text-[10px] text-[#808089] mt-1">
                          Thời gian: {item?.order_at}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col gap-1 items-center">
                      {getStatusDiv(item?.booking_status)}
                    </div>
                  </Row>
                </div>
              ))}

              {/* {detail?.BookingCode && (
                <>
              
                  <div
                    className="mt-2 w-full p-4 bg-white rounded-[20px] mb-4 "
                    style={{
                      boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <Row gutter={8} className="justify-between items-center">
                      <Col>
                        <Row gutter={4} align={"middle"}>
                          <Col>
                            <h4 className="font-bold text-[10px]">
                              {detail?.InfoFlight?.departFlight?.ListFlight?.StartDt.split(
                                " "
                              )[1].split(":")[0] +
                                ":" +
                                detail?.InfoFlight?.departFlight?.ListFlight?.StartDt.split(
                                  " "
                                )[1].split(":")[1]}
                            </h4>
                            <p className="text-[10px]">
                              {
                                detail?.InfoFlight?.departFlight?.ListFlight
                                  ?.StartPoint
                              }
                            </p>
                          </Col>
                          <Col className="justify-center text-center">
                            <p className="text-[10px] text-[#808089]">
                              {calculateTimeDifference(
                                detail?.InfoFlight?.departFlight?.ListFlight
                                  ?.StartDate,
                                detail?.InfoFlight?.departFlight?.ListFlight
                                  ?.EndDate
                              )}
                            </p>
                            <img src="/images/flight-line.svg" alt="" />

                            <p className="text-[10px] text-[#808089]">
                              {detail?.InfoFlight?.departFlight.ListFlight
                                .StopNum === 0
                                ? "Bay thẳng"
                                : `${detail?.InfoFlight?.departFlight.ListFlight.StopNum} điểm dừng`}
                            </p>
                          </Col>
                          <Col>
                            <h4 className="font-bold text-[10px]">
                              {detail?.InfoFlight?.departFlight?.ListFlight?.EndDt.split(
                                " "
                              )[1].split(":")[0] +
                                ":" +
                                detail?.InfoFlight?.departFlight?.ListFlight?.EndDt.split(
                                  " "
                                )[1].split(":")[1]}
                            </h4>
                            <p className="text-[10px]">
                              {
                                detail?.InfoFlight?.departFlight?.ListFlight
                                  .EndPoint
                              }
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <div className="flex gap-2 items-center mb-2">
                          <div className="font-bold text-primary text-[10px] ">
                            {formatNumberWithDots(
                              detail?.InfoFlight?.departFlight.TotalPrice
                            )}{" "}
                            {detail?.InfoFlight?.departFlight.Currency}
                          </div>
                        </div>

                        <div className="flex gap-2 items-center justify-between">
                          <p className="text-[10px] text-[#808089]">
                            /1 Hành khách
                          </p>
                          <span
                            className="text-primary text-[10px]"
                            onClick={() => {
                              setDetailTicket({
                                ...detail?.InfoFlight?.departFlight?.ListFlight,
                                ...detail?.InfoFlight?.departFlight,
                              });
                              setDrawerVisible(true);
                            }}
                          >
                            Chi tiết <img src="/images/2-down.svg" alt="" />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-2" />
                    <Row className="justify-between items-center">
                      <div className="justify-between w-full flex items-center">
                        <div className="flex gap-2 items-center ">
                          <img
                            className="w-auto h-4"
                            src={`https://abtrip.vn/images/airlines/${detail?.InfoFlight?.departFlight.Airline.toLowerCase()}.gif`}
                            alt=""
                          />
                          <div className="flex flex-col gap-1">
                            <p className="text-[10px]">
                              {detail?.InfoFlight?.departFlight.Airline || ""}
                            </p>
                            <p className="text-[10px] text-primary">
                              {detail?.InfoFlight?.departFlight?.ListFlight
                                ?.FlightNumber || ""}
                              /{" "}
                              {
                                detail?.InfoFlight?.departFlight?.ListFlight
                                  ?.GroupClass
                              }
                            </p>
                          </div>
                        </div>
                        <div className="text-white bg-[#FFBB29] rounded-[20px] px-4 py-1 w-fit text-[10px]">
                          {detail?.BookingStatusMessage}
                        </div>
                      </div>
                    </Row>
                    <Row className="justify-between items-center">
                      <p className="text-[10px] text-[#808089] mt-1">
                        Mã đơn hàng: {form.getFieldValue("OrderID")}
                      </p>
                    </Row>
                  </div>
                </>
              )} */}
              {/* {detail?.InfoFlight?.returnFlight?.BookingCode && (
                <div
                  className=" w-full p-4 bg-white rounded-[20px] mb-4 "
                  style={{
                    boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Row gutter={8} className="justify-between items-center">
                    <Col>
                      <Row gutter={4} align={"middle"}>
                        <Col>
                          <h4 className="font-bold text-[10px]">
                            {detail?.InfoFlight?.returnFlight?.ListFlight?.StartDt.split(
                              " "
                            )[1].split(":")[0] +
                              ":" +
                              detail?.InfoFlight?.returnFlight?.ListFlight?.StartDt.split(
                                " "
                              )[1].split(":")[1]}
                          </h4>
                          <p className="text-[10px]">
                            {
                              detail?.InfoFlight?.returnFlight?.ListFlight
                                ?.StartPoint
                            }
                          </p>
                        </Col>
                        <Col className="justify-center text-center">
                          <p className="text-[10px] text-[#808089]">
                            {calculateTimeDifference(
                              detail?.InfoFlight?.returnFlight?.ListFlight
                                ?.StartDate,
                              detail?.InfoFlight?.returnFlight?.ListFlight
                                ?.EndDate
                            )}
                          </p>
                          <img src="/images/flight-line.svg" alt="" />

                          <p className="text-[10px] text-[#808089]">
                            {detail?.InfoFlight?.returnFlight.ListFlight
                              .StopNum === 0
                              ? "Bay thẳng"
                              : `${detail?.InfoFlight?.returnFlight.ListFlight.StopNum} điểm dừng`}
                          </p>
                        </Col>
                        <Col>
                          <h4 className="font-bold text-[10px]">
                            {detail?.InfoFlight?.returnFlight?.ListFlight?.EndDt.split(
                              " "
                            )[1].split(":")[0] +
                              ":" +
                              detail?.InfoFlight?.returnFlight?.ListFlight?.EndDt.split(
                                " "
                              )[1].split(":")[1]}
                          </h4>
                          <p className="text-[10px]">
                            {
                              detail?.InfoFlight?.returnFlight?.ListFlight
                                .EndPoint
                            }
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <div className="flex gap-2 items-center mb-2">
                        <div className="font-bold text-primary text-[10px] ">
                          {formatNumberWithDots(
                            detail?.InfoFlight?.returnFlight.TotalPrice
                          )}{" "}
                          {detail?.InfoFlight?.returnFlight.Currency}
                        </div>
                      </div>

                      <div className="flex gap-2 items-center justify-between">
                        <p className="text-[10px] text-[#808089]">
                          /1 Hành khách
                        </p>
                        <span
                          className="text-primary text-[10px]"
                          onClick={() => {
                            setDetailTicket({
                              ...detail?.InfoFlight?.returnFlight?.ListFlight,
                              ...detail?.InfoFlight?.returnFlight,
                            });
                            setDrawerVisible(true);
                          }}
                        >
                          Chi tiết <img src="/images/2-down.svg" alt="" />
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-2" />
                  <Row className="justify-between items-center">
                    <div className="w-full flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <img
                          className="w-auto h-4"
                          src={`https://abtrip.vn/images/airlines/${detail?.InfoFlight?.returnFlight.Airline.toLowerCase()}.gif`}
                          alt=""
                        />
                        <div className="flex flex-col gap-1">
                          <p className="text-[10px]">
                            {detail?.InfoFlight?.returnFlight.Airline || ""}
                          </p>
                          <p className="text-[10px] text-primary">
                            {detail?.InfoFlight?.returnFlight?.ListFlight
                              ?.FlightNumber || ""}
                            /{" "}
                            {
                              detail?.InfoFlight?.returnFlight?.ListFlight
                                ?.GroupClass
                            }
                          </p>
                        </div>
                      </div>
                      <div className="text-white bg-[#FFBB29] rounded-[20px] px-4 py-1 w-fit text-[10px]">
                        {detail?.BookingStatusMessage}
                      </div>
                    </div>
                  </Row>
                  <Row className="justify-between items-center">
                    <p className="text-[10px] text-[#808089] mt-1">
                      Mã đơn hàng: {form.getFieldValue("OrderID")}
                    </p>
                 
                  </Row>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <TicketDetailsDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        ticketDetails={detailTicket}
        airports={airports}
      />
    </div>
  );
};

export default History;
