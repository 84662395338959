import React, { useState, useEffect, createContext, useRef } from "react";
import BgForm from "../../images/bg-form.png";
import IconFlight from "../../images/ic-flights.svg";
import {
  Input,
  Button,
  Form,
  Radio,
  Select,
  Tag,
  Checkbox,
  notification,
  Row,
  Col,
  Modal,
  Collapse,
} from "antd";
import { ForwardOutlined } from "@ant-design/icons";
import $axios from "../../services/axios";
import { checkTimeRange } from "../../helpers/utils";
import moment from "moment";
import _ from "lodash";
const ReachableContext = createContext(null);
const { Option } = Select;
const { Search, TextArea } = Input;

const Step3 = ({
  onChangeStep,
  onChangeBookingInfo,
  airportInfo,
  chooseFlight,
  setLoading,
  isPromotionOrSameDayTicket,
  setStep,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const formRef = useRef(null);
  const config = {
    title: "Thông báo!",
    maskClosable: false,
    closable: false,
    footer: [
      <div className="text-center w-full">
        <Button
          key="ok"
          type="primary"
          onClick={() => (window.location.href = "/vemaybay")}
          className="bg-[#0064D2] text-white"
        >
          Về trang tìm kiếm
        </Button>
      </div>,
    ],
    content: (
      <>
        <ReachableContext.Consumer>
          {() => (
            <div className="text-[14px] text-[#0D1634] font-medium">
              Chuyến bay đã có sự thay đổi về giá. Vui lòng tìm kiếm lại chuyến
              bay để cập nhật!
            </div>
          )}
        </ReachableContext.Consumer>
        <br />
      </>
    ),
  };
  const [form] = Form.useForm();
  const invoice = Form.useWatch("invoice", form);
  const [baggages, setBaggages] = useState([]);
  const [baggagesOut, setBaggagesOut] = useState([]);
  const [priceAdded, setPriceAdded] = useState(null);
  const [passengerIdChoose, setPassengerIdChoose] = useState(null);
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucherCode, setVoucherCode] = useState(null);
  const [voucherTxt, setVoucherTxt] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [processPriceDone, setProcessPriceDone] = useState(false);
  const [pricesAddList, setPricesAddList] = useState([]);
  const [seatmaps, setSeatmaps] = useState([]);
  const FlightType = _.get(chooseFlight, "[0].FlightType");
  const Session = _.get(chooseFlight, "[0].Session");
  const AutoIssue = _.get(chooseFlight, "[0].AutoIssue");
  const currentYear = moment().year();
  const [showPriceDetail, setShowPriceDetail] = useState(false);
  const [listPrice, setListPrice] = useState({
    depart: [],
    return: [],
  });
  const [totalListPrice, setTotalListPrice] = useState({
    totalFixed: 0,
    baggage: 0,
    baggage_out: 0,
    totalDisplay: 0,
    totalPriceAdd: 0,
    totalTax: 0,
    totalFareAdt: 0,
    totalFareChd: 0,
    totalFareInf: 0,
    totalVat: 0,
    totalFee: 0,
  });
  const minYearCHD = currentYear - 12;
  const maxYearCHD = currentYear - 2;
  const minYearINF = currentYear - 2;
  const maxYearINF = currentYear - 0;
  // const eligibleYear = currentYear - 13; // Năm cần chọn phải lớn hơn 12 tuổi
  // const startYear = 1940; // Năm bắt đầu
  // const yearsADT = Array.from({ length: eligibleYear - startYear + 1 }, (_, i) => startYear + i);
  const yearsCHD = Array.from(
    { length: maxYearCHD - minYearCHD + 1 },
    (_, i) => minYearCHD + i
  );
  const yearsINF = Array.from(
    { length: maxYearINF - minYearINF + 1 },
    (_, i) => minYearINF + i
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const days = Array.from({ length: 31 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );

  const ListPassengerAdt =
    _.get(chooseFlight, "[0].Adt", 0) > 0
      ? _.range(1, _.get(chooseFlight, "[0].Adt", 0) + 1).map((item) => {
          return {
            id: `adt-${item}`,
            FirstName: "",
            LastName: "",
            Type: "ADT", // loại khách ADT, CHD, INF
            IdCard: "",
            PassportNumber: "",
            Gender: "male",
            Membership: "",
            Birthday: "",
            ListBaggage: [],
            // ListSeat: [],
          };
        })
      : [];

  const ListPassengerChd =
    _.get(chooseFlight, "[0].Chd", 0) > 0
      ? _.range(1, _.get(chooseFlight, "[0].Chd", 0) + 1).map((item) => {
          return {
            id: `chd-${item}`,
            FirstName: "",
            LastName: "",
            Type: "CHD", // loại khách ADT, CHD, INF
            IdCard: "",
            PassportNumber: "",
            Gender: "male",
            Membership: "",
            Birthday: "",
            ListBaggage: [],
            // ListSeat: [],
          };
        })
      : [];

  const ListPassengerInf =
    _.get(chooseFlight, "[0].Inf", 0) > 0
      ? _.range(1, _.get(chooseFlight, "[0].Inf", 0) + 1).map((item) => {
          return {
            id: `inf-${item}`,
            FirstName: "",
            LastName: "",
            Type: "INF",
            IdCard: "",
            PassportNumber: "",
            Gender: "male",
            Membership: "",
            Birthday: "",
            ListBaggage: [],
            // ListSeat: [],
          };
        })
      : [];

  const ListPassenger = _.concat(
    ListPassengerAdt,
    ListPassengerChd,
    ListPassengerInf
  ).map((item, index) => {
    item.Index = index + 1;
    return item;
  });

  const initialValues = _.reduce(
    ListPassenger,
    (acc, num) => {
      acc[`gender_${num.id}`] = "male";
      return acc;
    },
    { genderContact: "male" }
  );

  // công thức giá vé nét là giá vé + thuế  x số người
  // công thức giá vé tổng là giá vé nét + phí dịch vụ

  const verifyFlight = async (value) => {
    setLoading(true);
    try {
      const body = {
        ListFareData: _.map(chooseFlight, (f) => {
          return {
            Session: Session,
            FareDataId: f.FareDataId,
            ListFlight: f.ListFlight.map((f2) => {
              return {
                FlightValue: f2.FlightValue,
              };
            }),
          };
        }),
      };
      const res = await $axios.$post("/flights/verifyflight", body);
      if (res.data.ErrorCode === "000") {
        const totalNetPriceOrigin =
          _.get(chooseFlight, "[0].TotalNetPrice", 0) +
          _.get(chooseFlight, "[1].TotalNetPrice", 0);
        const totalNetPriceCompare =
          _.get(res.data, "ListFareStatus[0].FareData.TotalNetPrice", 0) +
          _.get(res.data, "ListFareStatus[1].FareData.TotalNetPrice", 0);
        return totalNetPriceOrigin === totalNetPriceCompare;
      } else {
        return false;
      }
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async (values) => {
    const canBooking = await verifyFlight();
    // check giá xem có được booking hay không
    if (!canBooking) {
      await modal.confirm(config);
      return;
    }
    setLoading(true);
    try {
      const listFareDataInbound = _.map(chooseFlight, (f) => {
        return {
          Session: Session,
          FareDataId: f.FareDataId,
          ListFlight: f.ListFlight.map((f2) => {
            return {
              FlightValue: f2.FlightValue,
            };
          }),
        };
      });
      const listFareDataOutbound = _.map(chooseFlight, (f) => {
        return {
          Session: Session,
          FareDataId: f.FareDataId,
          AutoIssue: AutoIssue,
          ListFlight: f.ListFlight.map((f2) => {
            return {
              FlightValue: f2.FlightValue,
            };
          }),
        };
      });

      const _ListPassenger = ListPassenger.map((p) => {
        p.FirstName = values[`surname_${p.id}`];
        p.LastName = values[`lastName_${p.id}`];
        p.Gender = values[`gender_${p.id}`] === "male" ? true : false;
        const baggage = baggages.filter(
          (b) => b.Value === values[`baggage_${p.id}`]
        );
        const baggageOut = baggagesOut.filter(
          (b) => b.Value === values[`baggage_out_${p.id}`]
        );
        if (baggage.length > 0 || baggageOut.length > 0) {
          p.ListBaggage =
            chooseFlight.length === 1 ? baggage : _.concat(baggage, baggageOut);
        } else {
          delete p.ListBaggage;
        }
        // p.ListSeat = seatmaps.filter(
        //   (b) => b.Value === values[`seatmap_${p.id}`]
        // );
        if (p.Type !== "ADT") {
          p.Birthday = `${values[`day_${p.id}`]}${values[`month_${p.id}`]}${
            values[`year_${p.id}`]
          }`;
        }
        delete p.id;
        return p;
      });

      const body = {
        BookType: "book-normal",
        UseAgentContact: "true",
        Channel: "LICHVIET",
        Contact: {
          FirstName: values.surnameContact,
          LastName: values.lastNameContact,
          Gender: values.genderContact === "male" ? "true" : "false",
          Phone: values.phone,
          Email: values.email,
        },
        ListPassenger: _ListPassenger,
        ListFareData:
          FlightType === "domestic"
            ? listFareDataInbound
            : listFareDataOutbound,
      };

      if (invoice) {
        body.Invoice = {
          TaxCode: values.taxCode,
          ReceiverReceive: values.receiverReceive,
        };
      }
      if (isPromotionOrSameDayTicket) {
        body.AutoIssue = true;
      }
      let bookingInfo = {
        voucherValue,
        voucherCode,
        listPrice,
        priceAdded,
        totalListPrice,
        Contact: {
          FirstName: values.surnameContact,
          LastName: values.lastNameContact,
          Gender: values.genderContact,
          Phone: values.phone,
          Email: values.email,
        },
        bodyBooking: body,
        isPromotionOrSameDayTicket,
        chooseFlight,
      };
      // Thanh toán trước gọi api booking sau
      if (isPromotionOrSameDayTicket) {
        try {
          await createTransactionVmb(bookingInfo, values);
          setTimeout(() => {
            onChangeBookingInfo(bookingInfo);
            onChangeStep(4);
          }, 0);
        } catch (error) {
          console.log(error, "error");
          notification.error({
            status: "error",
            message: "Thất bại",
            description: "Đã có lỗi xảy ra!",
          });
        }
      }
      // Booking trước , thanh toán sau
      else {
        await createTransactionVmb(bookingInfo, values);
        const res = await $axios.$post("/flights/bookflight", body);
        if (res.data.ErrorCode === "000") {
          bookingInfo = {
            ...bookingInfo,
            ...res.data,
            OrderIDMapping: res.data.OrderID.replace("ABT", "IPASS"),
          };

          updateTransactionVmb(bookingInfo, true);
        } else {
          updateTransactionVmb(bookingInfo, false);
          notification.error({
            status: "error",
            message: "Thất bại",
            description: res.data.Message,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const createTransactionVmb = async (bookingInfo, values) => {
    const token = localStorage.getItem("verifyToken");
    let orderId = `IPASS${Date.now()}`;
    bookingInfo.OrderID = orderId;
    const orderDetail = chooseFlight.map((item, index) => {
      // chuyến 1 chiều, hoặc chuyến 2 chiều cùng mã máy bay
      let price_original =
        index === 0
          ? item.TotalPrice + bookingInfo.totalListPrice.baggage
          : item.TotalPrice + bookingInfo.totalListPrice.baggage_out;
      let price =
        index === 0
          ? item.TotalPriceNew + bookingInfo.totalListPrice.baggage
          : item.TotalPriceNew + bookingInfo.totalListPrice.baggage_out;
      return {
        order_code: orderId, //mã đơn hàng
        order_code_mapping: orderId, //mã tham chiếu đến ipass
        booking_code: "", //mã booking
        airline: item?.ListFlight[0]?.Airline, //hãng hàng không
        route: `${item?.ListFlight[0]?.StartPoint}${item?.ListFlight[0]?.EndPoint}`, //chuyến bay
        expired_at: "", //thời gian hết hạn  2022-02-02 02:02:02
        purchase_at: "", //thời gian mua 2022-02-02 02:02:02
        booking_at: "", //ngày đặt
        amount: price, //giá tổng
        amount_original: price_original, //giá gốc tổng
        amount_discount: 0, //giảm giá tổng
        booking_status: 1, // 1 đặt vé, 2 - giữ chỗ, 3 - xuất vé, -1: lỗi đặt vé, -2 - lỗi giữ chỗ, -3 lỗi xuất vé
        start_point: item?.ListFlight[0]?.StartPoint, //điếm đi
        end_point: item?.ListFlight[0]?.EndPoint, //điểm đến
        start_at: moment(item?.ListFlight[0]?.StartDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ), //thời gian đi
        end_at: moment(item?.ListFlight[0]?.EndDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ), //thời gian về
        flight_number: item?.ListFlight[0]?.FlightNumber, //số hiệu máy bay
        flight_type: item.FlightType, //loại chuyển bay: domestic(nội địa), international: (quốc tế'
        group_class: item?.ListFlight[0]?.GroupClass, //hạng vé
        fare_class: item?.ListFlight[0]?.FareClass, //hạng vé
        amount_fare: _.sum([
          item.TotalFareAdtNew,
          item.TotalFareChdNew,
          item.TotalFareInfNew,
        ]), //giá vé
        amount_tax: item.TotalTax, //tiền thuế
        amount_fee: item.TotalServiceFee, //tiền dịch vụ
        amount_vat: _.sum([item.VatAdt, item.VatChd, item.VatInf]), //tiền vat
        type_flight: index === 0 ? "depart" : index === 1 ? "return" : "",
        hand_baggage: _.get(
          item,
          "ListFlight[0].ListSegment[0].HandBaggage",
          ""
        ),
        allowance_baggage: _.get(
          item,
          "ListFlight[0].ListSegment[0].AllowanceBaggage",
          ""
        ),
        city_name_start: _.get(
          airportInfo.find(
            (air) => air.code === item?.ListFlight[0]?.StartPoint
          ),
          "city_name_en",
          ""
        ),
        city_name_end: _.get(
          airportInfo.find((air) => air.code === item?.ListFlight[0]?.EndPoint),
          "city_name_en",
          ""
        ),
      };
    });
    const bodyTransaction = {
      token: token,
      transaction: {
        order_code: orderId, // mã đơn hàng, required,
        order_code_mapping: orderId, // mã tham chiếu đến ipass,
        payment_transaction: "", // mã giao dịch của bên thanh toán
        payment_bankTran: "1", // mã giao dịch tại ngân hàng,
        payment_at: "", // thời điểm thanh toán
        payment_partner: "vtcpay", // đối tác thanh toán: vnpay hoặc vtcpay,
        payment_method: "", // phương thức thanh toán: ví, ngân hàng ...
        payment_status: 0, // 1-thành công vtcpay
        payment_term_at: "", // thời hạn thanh toán
        payment_amount: totalListPrice.totalDisplay, // tổng tiền thanh toán vtcpay
        route: `${chooseFlight[0]?.ListFlight[0]?.StartPoint}${chooseFlight[0]?.ListFlight[0]?.EndPoint}`, //điểm đi và điểm đến
        amount: totalListPrice.totalDisplay, //tổng tiền thanh toán booking
        amount_original:
          totalListPrice.totalDisplay -
          totalListPrice.totalPriceAdd -
          bookingInfo.voucherValue, // tổng tiền gốc
        amount_discount: voucherValue, // tổng tiền giảm giá
        amount_fee: totalListPrice.totalFee, // tổng phí dịch vụ // update later
        vat_fullName: values.receiverReceive, //vat - tên
        vat_mst: values.taxCode, //vat - mst
        vat_email: values.email,
        vat_company: "", //vat - tên công ty
        booking_status: 1, //trạng thái vé:  1 đặt vé, 2 - giữ chỗ, 3 - xuất vé, -1: lỗi đặt vé, -2 - lỗi giữ chỗ, -3 lỗi xuất vé
        order_at: moment().format("YYYY-MM-DD HH:mm:ss"), //ngày đặt
        start_at: moment(chooseFlight[0]?.ListFlight[0]?.StartDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ), //ngày khởi hành
        end_at: moment(
          chooseFlight.length === 2
            ? chooseFlight[1]?.ListFlight[0]?.StartDate
            : chooseFlight[0]?.ListFlight[0]?.StartDate
        ).format("YYYY-MM-DD HH:mm:ss"), //ngày về
        voucher_code: voucherCode, //mã voucher
        customer_fullname: `${bookingInfo.Contact.FirstName} ${bookingInfo.Contact.LastName}`, //tên khách hàng
        customer_phone: bookingInfo.Contact.Phone, //số điện thoại
        customer_cccd: "", //căn cước công dân/passport
      },
      orderDetail,
    };
    try {
      const resTrans = await $axios.$post("/transaction", bodyTransaction);
      bodyTransaction.transaction.id = resTrans.data.transaction.id;
      bodyTransaction.orderDetail.map((order) => {
        order["id"] = _.get(
          _.find(
            resTrans.data.transactionBooking,
            (item) => item.type_flight === order.type_flight
          ),
          "id"
        );
        return order;
      });
      bodyTransaction.transactionFare = resTrans.data.transactionFare;
      bookingInfo.bodyTransaction = bodyTransaction;
      sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
    }
  };

  const updateTransactionVmb = async (bookingInfo, bookingABTripSucess) => {
    if (!bookingABTripSucess) {
      const bodyUpdateTransction = {
        token: bookingInfo.bodyTransaction.token,
        transaction: {
          ...bookingInfo.bodyTransaction.transaction,
          booking_status: -2,
        },
        orderDetail: bookingInfo.bodyTransaction.orderDetail.map((order) => {
          order.booking_status = -2;
          return order;
        }),
        transactionFare: bookingInfo.bodyTransaction.transactionFare.map(
          (item) => {
            item.booking_status = -2;
            return item;
          }
        ),
        payment_result: {
          amount: "",
          message: "",
          payment_type: "",
          reference_number: "",
          status: 0,
          trans_ref_no: "",
          website_id: "",
          signature: "",
        },
      };

      try {
        await $axios.$post("/update", bodyUpdateTransction);
        bookingInfo.bodyTransaction = bodyUpdateTransction;
        sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
      } catch (error) {
        notification.error({
          status: "error",
          message: "Thất bại",
          description: "Đã có lỗi xảy ra!",
        });
      } finally {
      }
      return;
    }
    let _orderDetail = _.cloneDeep(bookingInfo.bodyTransaction.orderDetail);
    _orderDetail.map((item, index) => {
      let BookingCode = "";
      let ExpiryDate = "";
      // chuyến 1 chiều, hoặc chuyến 2 chiều cùng mã máy bay
      if (
        chooseFlight.length === 1 ||
        (chooseFlight.length === 2 && bookingInfo.ListBooking.length === 1)
      ) {
        BookingCode = bookingInfo.ListBooking[0].BookingCode;
        ExpiryDate = bookingInfo.ListBooking[0].ExpiryDate;
        // chuyến 2 chiều khác mã máy bay
      } else {
        // get booking code chiều đi
        BookingCode = bookingInfo.ListBooking[index].BookingCode;
        ExpiryDate = bookingInfo.ListBooking[0].ExpiryDate;
      }
      item.order_code = bookingInfo.OrderID;
      item.order_code_mapping = bookingInfo.OrderIDMapping;
      item.booking_code = BookingCode;
      item.expired_at = moment(ExpiryDate).format("YYYY-MM-DD HH:mm:ss");
      item.booking_at = moment(ExpiryDate).format("YYYY-MM-DD HH:mm:ss");
      item.purchase_at = moment(ExpiryDate).format("YYYY-MM-DD HH:mm:ss");
      item.booking_status = 2;
      return item;
    });
    let transactionFare = _.cloneDeep(
      bookingInfo.bodyTransaction.transactionFare
    );
    transactionFare.map((trans) => {
      const orderByTrans = _orderDetail.find(
        (order) =>
          order.start_point === trans.start_point &&
          order.end_point === trans.end_point
      );
      trans.order_code = orderByTrans.order_code;
      trans.order_code_mapping = orderByTrans.order_code_mapping;
      trans.booking_code = orderByTrans.booking_code;
      trans.expired_at = orderByTrans.expired_at;
      trans.booking_at = orderByTrans.booking_at;
      trans.purchase_at = orderByTrans.purchase_at;
      trans.booking_status = orderByTrans.booking_status;
      delete trans.created_at;
      return trans;
    });

    const bodyUpdateTransction = {
      token: bookingInfo.bodyTransaction.token,
      transaction: {
        ...bookingInfo.bodyTransaction.transaction,
        order_code: bookingInfo.OrderID,
        order_code_mapping: bookingInfo.OrderIDMapping,
        order_at: moment().format("YYYY-MM-DD HH:mm:ss"), //ngày đặt
        amount_original: bookingInfo.TotalPrice,
        booking_status: 2,
      },
      orderDetail: _orderDetail,
      transactionFare,
      payment_result: {
        amount: "",
        message: "",
        payment_type: "",
        reference_number: "",
        status: 0,
        trans_ref_no: "",
        website_id: "",
        signature: "",
      },
    };

    try {
      await $axios.$post("/update", bodyUpdateTransction);
      bookingInfo.bodyTransaction = bodyUpdateTransction;
      sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
      onChangeBookingInfo(bookingInfo);
      onChangeStep(4);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
    }
  };

  useEffect(() => {
    getBaggage();
    getListVoucher();
    getPricesAddList();
    // const chooseFlightValid = handleCheckChooseFlight();
    // if (chooseFlightValid) {

    // } else {
    //   modal.confirm(config);
    // }
  }, []);

  useEffect(() => {
    if (passengerIdChoose) {
      handleChangeContactPersion();
    }
  }, [passengerIdChoose]);

  // const handleCheckChooseFlight = () => {
  //   let listPriceIn = _.get(chooseFlight, "[0].ListFlight[0].ListPrice", []);
  //   let listPriceOut = _.get(chooseFlight, "[1].ListFlight[0].ListPrice", []);
  //   return chooseFlight.length > 1 ? listPriceIn.length > 0 && listPriceOut.length > 0 : listPriceIn.length > 0;
  // };
  const handleInputChange = (e, field) => {
    const uppercaseValue = e.target.value
      .normalize("NFD") // Chuyển ký tự có dấu thành tổ hợp ký tự
      .replace(/[\u0300-\u036f]/g, "") // Loại bỏ các dấu
      .replace(/đ/g, "d") // Chuyển 'đ' thành 'd'
      .replace(/Đ/g, "D") // Chuyển 'Đ' thành 'D'
      .toUpperCase(); // Chuyển thành chữ in hoa;
    form.setFieldsValue({ [field]: uppercaseValue }); // Cập nhật giá trị trong form
  };

  // lấy list ghế
  const getSeatmap = async () => {
    setLoading(true);
    try {
      const res = await $axios.$post("/flights/getseatmap", {
        ListFareData: _.map(chooseFlight, (f) => {
          return {
            Session: f.Session,
            FareDataId: f.FareDataId,
            ListFlight: _.map(f.ListFlight, (f2) => {
              return {
                FlightValue: f2.FlightValue,
              };
            }),
          };
        }),
      });
      //  setSeatmaps(_.get(res, "data.ListBaggage"));
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  // lấy hành lý
  const getBaggage = async () => {
    setLoading(true);
    try {
      const res = await $axios.$post("/flights/getbaggage", {
        ListFareData: [
          {
            Session: chooseFlight[0].Session,
            FareDataId: chooseFlight[0].FareDataId,
            ListFlight: _.map(chooseFlight[0].ListFlight, (f) => {
              return {
                FlightValue: f.FlightValue,
              };
            }),
          },
        ],
      });
      setBaggages(_.get(res, "data.ListBaggage"));
      if (chooseFlight.length === 2) {
        const res = await $axios.$post("/flights/getbaggage", {
          ListFareData: [
            {
              Session: chooseFlight[1].Session,
              FareDataId: chooseFlight[1].FareDataId,
              ListFlight: _.map(chooseFlight[1].ListFlight, (f) => {
                return {
                  FlightValue: f.FlightValue,
                };
              }),
            },
          ],
        });
        setBaggagesOut(_.get(res, "data.ListBaggage"));
      }
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const getListVoucher = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get("/voucher");
      setVouchers(res.data.vouchers);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const getPricesAddList = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get("/pricing");
      setPricesAddList(res.data.price_discount);
      calculatePriceAdd(res.data.price_discount);
    } catch (error) {
      calculatePriceAdd([]);
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      // setTimeout(() => {
      // }, 2000);
      setLoading(false);
    }
  };

  const calculatePriceAdd = (price_discount) => {
    const _priceAdded = _.find(
      price_discount,
      (v) => v.code === checkTimeRange(chooseFlight[0].ListFlight[0].StartDate)
    );
    setPriceAdded(_priceAdded);
    chooseFlight = chooseFlight.map((f) => {
      let totalPriceAdd = 0;
      if (_priceAdded && _priceAdded.amount) {
        totalPriceAdd = _.sum([f.Adt, f.Chd, f.Inf]) * _priceAdded.amount;
      }
      const fareAdtNew =
        _priceAdded && f.Adt > 0 ? f.FareAdt + _priceAdded.amount : f.FareAdt;
      const fareChdNew =
        _priceAdded && f.Chd > 0 ? f.FareChd + _priceAdded.amount : f.FareChd;
      const fareInfNew =
        _priceAdded && f.Inf > 0 ? f.FareInf + _priceAdded.amount : f.FareInf;
      f.FareAdtNew = fareAdtNew; // giá vé tăng 1 ng
      f.FareChdNew = fareChdNew; // giá vé tăng 1 ng
      f.FareInfNew = fareInfNew; // giá vé tăng 1 ng
      f.TotalFareAdtNew = fareAdtNew * f.Adt;
      f.TotalFareChdNew = fareChdNew * f.Chd;
      f.TotalFareInfNew = fareInfNew * f.Inf;
      f.TotalNetPriceNew = _priceAdded
        ? f.TotalNetPrice + totalPriceAdd
        : f.TotalNetPrice;
      f.TotalPriceNew = _priceAdded
        ? f.TotalPrice + totalPriceAdd
        : f.TotalPrice;
      f.TotalTax = _.sum([
        f.TaxAdt * f.Adt,
        f.TaxChd * f.Chd,
        f.TaxInf * f.Inf,
      ]);
      f.TotalVat = _.sum([f.VatAdt, f.VatChd, f.VatInf]);
      f.TotalPriceFixed = _priceAdded
        ? f.TotalPrice + totalPriceAdd
        : f.TotalPrice;
      f.TotalPriceAdd = totalPriceAdd;
      return f;
    });
    setProcessPriceDone(true);
    setTotalListPrice({
      ...totalListPrice,
      totalFixed: _.sum(_.map(chooseFlight, (f) => f.TotalPriceNew)),
      totalDisplay: _.sum(_.map(chooseFlight, (f) => f.TotalPriceNew)),
      totalPriceAdd: _.sum(_.map(chooseFlight, (f) => f.TotalPriceAdd)),
      totalTax: _.sum(_.map(chooseFlight, (f) => f.TotalTax)),
      totalFareAdt: _.sum(_.map(chooseFlight, (f) => f.TotalFareAdtNew)),
      totalFareChd: _.sum(_.map(chooseFlight, (f) => f.TotalFareChdNew)),
      totalFareInf: _.sum(_.map(chooseFlight, (f) => f.TotalFareInfNew)),
      totalFee: _.sum(_.map(chooseFlight, (f) => f.TotalServiceFee)),
      totalVat: _.sum(_.map(chooseFlight, (f) => f.TotalVat)),
    });
  };

  const applyVoucher = (e) => {
    const voucher = _.find(vouchers, (v) => v.code === e);
    if (voucher) {
      setVoucherValue(voucher.amount);
      setVoucherCode(voucher.code);
      setVoucherTxt(
        `<div class="text-[14px] ">Áp dụng mã giảm giá thành công! <span class="text-green-600 text-[16px]">
        ${voucher.amount.toLocaleString("vi-VN")} đ</span></div>`
      );
      setTotalListPrice({
        ...totalListPrice,
        totalDisplay:
          totalListPrice.totalFixed +
          totalListPrice.baggage +
          totalListPrice.baggage_out -
          voucher.amount,
      });
    } else {
      setTotalListPrice({
        ...totalListPrice,
        totalDisplay:
          totalListPrice.totalFixed +
          totalListPrice.baggage +
          totalListPrice.baggage_out,
      });
      setVoucherTxt(
        `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
      );
      setVoucherValue(0);
      setVoucherCode("");
    }
  };

  const handleChangeContactPersion = () => {
    form.setFieldsValue({
      surnameContact: form.getFieldValue(`surname_${passengerIdChoose}`),
      lastNameContact: form.getFieldValue(`lastName_${passengerIdChoose}`),
      genderContact: form.getFieldValue(`gender_${passengerIdChoose}`),
    });
  };

  const handleValuesFormChange = (changedValues, allValues) => {
    let totalBaggage = 0;
    let totalBaggageOut = 0;
    let totalBaggageIn = 0;
    Object.keys(allValues).forEach((key) => {
      if (key.includes("baggage_")) {
        totalBaggage += _.get(
          _.find(baggages, (b) => b.Value === allValues[key]),
          "Price",
          0
        );
      }
      if (key.includes("baggage_out_")) {
        totalBaggageOut += _.get(
          _.find(baggages, (b) => b.Value === allValues[key]),
          "Price",
          0
        );
      }
    });
    totalBaggageIn = totalBaggage - totalBaggageOut;
    setTotalListPrice({
      ...totalListPrice,
      baggage: totalBaggageIn,
      baggage_out: totalBaggageOut,
      totalDisplay:
        totalListPrice.totalFixed + totalBaggageIn + totalBaggageOut,
    });
  };

  return (
    <div className="bg-[#F6F6F6] mb-4">
      <div className="bg-[#3691e2] h-[300px] relative">
        <section className="step-indicator">
          <div className="step step1 actived">
            <div className="step-icon" onClick={() => onChangeStep(1)}>
              1
            </div>
            <p>Tìm kiếm</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step2 actived">
            <div className="step-icon">2</div>
            <p>Chọn vé</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step3 active">
            <div className="step-icon">3</div>
            <p>Thông tin</p>
          </div>
          <div className="indicator-line"></div>
          <div className="step step4">
            <div className="step-icon">4</div>
            <p>Thanh Toán</p>
          </div>
        </section>
        <img src={BgForm} alt="" className="w-full object-cover mx-auto" />
        <div className="flex w-full absolute top-[135px] left-1/2 transform -translate-x-1/2 justify-center gap-8">
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight?.[0]?.ListFlight?.[0]?.StartPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].StartPoint
                )?.city_name_vi
              }
            </div>
          </div>
          <div className="relative">
            <img src={IconFlight} alt="" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight[0]?.ListFlight?.[0]?.EndPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].EndPoint
                )?.city_name_vi
              }
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto absolute top-[200px] left-1/2 transform -translate-x-1/2 pb-[150px] flex flex-col">
          <Form
            ref={formRef}
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={(values) => {
              notification.error({
                message: "Thông báo",
                description: "Vui lòng nhập đầy đủ thông tin",
              });
            }}
            autoComplete="off"
            className="formCreateUser"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={initialValues}
            onValuesChange={handleValuesFormChange}
          >
            <div
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className="rounded-[20px] bg-white p-4 mb-[24px] text-[#0D1634]"
            >
              <div className="text-[20px] font-medium mb-1">
                Thông tin hành khách
              </div>
              <div className="text-[#D72F20] mb-3">(*) là bắt buộc</div>
              {ListPassenger.map((i, index) => (
                <div key={index}>
                  <Form.Item className="mb-2">
                    <div className="flex justify-between">
                      <div className="text-[14px] flex justify-between">
                        {index + 1}.{" "}
                        {i.Type === "ADT"
                          ? "Người lớn"
                          : i.Type === "CHD"
                            ? "Trẻ em"
                            : "Em bé"}
                      </div>
                      {i.Type === "ADT" && (
                        <Checkbox
                          checked={i.id === passengerIdChoose}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setPassengerIdChoose(i.id);
                            } else {
                              setPassengerIdChoose(null);
                            }
                          }}
                        >
                          Là người liên hệ
                        </Checkbox>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item name={`gender_${i.id}`} className="mb-3">
                    <Radio.Group>
                      <Radio value="male" className="text-[14px] font-medium">
                        {i.Type === "ADT" ? "Ông" : "Trai"}
                      </Radio>
                      <Radio value="female" className="text-[14px] font-medium">
                        {i.Type === "ADT" ? "Bà" : "Gái"}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Row gutter={8} className="mb-3">
                    <Col span={12}>
                      <Form.Item
                        name={`surname_${i.id}`}
                        label={
                          <div className="text-[14px] font-medium">Họ</div>
                        }
                        rules={[
                          { required: true, message: "Họ không được để trống" },
                        ]}
                        className="mb-0"
                      >
                        <Input
                          placeholder="NGUYEN"
                          onChange={(e) => {
                            handleInputChange(e, `surname_${i.id}`);
                            handleChangeContactPersion();
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <div className="text-[14px] font-medium">
                            Tên đệm & Tên
                          </div>
                        }
                        name={`lastName_${i.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Tên đệm & tên không được để trống",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Input
                          placeholder="VAN A"
                          onChange={(e) => {
                            handleInputChange(e, `lastName_${i.id}`);
                            handleChangeContactPersion();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {i.Type !== "ADT" && (
                    <Row gutter={8} className="mb-3">
                      <Col span={8}>
                        <Form.Item
                          name={`day_${i.id}`}
                          rules={[
                            { required: true, message: "Vui lòng chọn ngày" },
                          ]}
                          className="mb-0"
                        >
                          <Select placeholder="Ngày">
                            {days.map((day) => (
                              <Select.Option key={day} value={day}>
                                {day}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={`month_${i.id}`}
                          rules={[
                            { required: true, message: "Vui lòng chọn tháng" },
                          ]}
                          className="mb-0"
                        >
                          <Select placeholder="Tháng">
                            {months.map((month) => (
                              <Select.Option key={month} value={month}>
                                {month}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={`year_${i.id}`}
                          rules={[
                            { required: true, message: "Vui lòng chọn năm" },
                          ]}
                          className="mb-0"
                        >
                          <Select placeholder="Năm">
                            {(i.Type === "CHD" ? yearsCHD : yearsINF).map(
                              (year) => (
                                <Select.Option key={year} value={year}>
                                  {year}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {chooseFlight.length === 1 ? (
                    <>
                      <Form.Item
                        label="+ Hành lý ký gửi"
                        name={`baggage_${i.id}`}
                        className="mb-3"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Chọn hành lý ký gửi"
                        >
                          <Option key={-1} value={-1}>
                            Chọn hành lý ký gửi
                          </Option>
                          {baggages.map((item) => (
                            <Option key={item.Value} value={item.Value}>
                              {item.Name} ({item.Price.toLocaleString("vi-VN")}
                              ₫)
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {chooseFlight[0].ListFlight[0].ListSegment[0]
                        .HandBaggage && (
                        <Tag
                          color="#4480E7"
                          className="text-[14px] font-semibold rounded-[20px] px-2 py-1 mb-3 mr-0"
                        >
                          Đã bao gồm{" "}
                          {
                            chooseFlight[0].ListFlight[0].ListSegment[0]
                              .HandBaggage
                          }{" "}
                          xách tay
                        </Tag>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Item
                        label="+ Hành lý ký gửi chiều đi"
                        name={`baggage_${i.id}`}
                        className="mb-3"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Chọn hành lý ký gửi"
                        >
                          <Option key={-1} value={-1}>
                            Chọn hành lý ký gửi
                          </Option>
                          {baggages.map((item) => (
                            <Option key={item.Value} value={item.Value}>
                              {item.Name} ({item.Price.toLocaleString("vi-VN")}
                              ₫)
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {chooseFlight[0].ListFlight[0].ListSegment[0]
                        .HandBaggage && (
                        <Tag
                          color="#4480E7"
                          className="text-[14px] font-semibold rounded-[20px] px-2 py-1 mb-3 mr-0"
                        >
                          Đã bao gồm{" "}
                          {
                            chooseFlight[0].ListFlight[0].ListSegment[0]
                              .HandBaggage
                          }{" "}
                          xách tay
                        </Tag>
                      )}
                      <Form.Item
                        label="+ Hành lý ký gửi chiều về"
                        name={`baggage_out_${i.id}`}
                        className="mb-3"
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Chọn hành lý ký gửi"
                        >
                          <Option key={-1} value={-1}>
                            Chọn hành lý ký gửi
                          </Option>
                          {baggagesOut.map((item) => (
                            <Option key={item.Value} value={item.Value}>
                              {item.Name} ({item.Price.toLocaleString("vi-VN")}
                              ₫)
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {chooseFlight[1].ListFlight[0].ListSegment[0]
                        .HandBaggage && (
                        <Tag
                          color="#4480E7"
                          className="text-[14px] font-semibold rounded-[20px] px-2 py-1 mb-3 mr-0"
                        >
                          Đã bao gồm{" "}
                          {
                            chooseFlight[1].ListFlight[0].ListSegment[0]
                              .HandBaggage
                          }{" "}
                          xách tay
                        </Tag>
                      )}
                    </>
                  )}

                  {/* <Form.Item label="Chọn ghế" name={`seatmap_${i}`} className="mb-3">
                    <Select style={{ width: "100%" }} placeholder="Chọn ghế">
                      {seatmaps.map((item) => (
                        <Option key={item.Value} value={item.Value}>
                          {item.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </div>
              ))}
              <div className="text-[#0D1634] text-[20px] mb-1">
                Thông tin liên hệ
              </div>
              <div className="text-[#D72F20] text-[14px] mb-3">
                (*) là bắt buộc
              </div>
              <Form.Item name="genderContact" className="mb-3">
                <Radio.Group>
                  <Radio value="male" className="text-[14px] font-medium">
                    Ông
                  </Radio>
                  <Radio value="female" className="text-[14px] font-medium">
                    Bà
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Row gutter={8} className="mb-3">
                <Col span={12}>
                  <Form.Item
                    name="surnameContact"
                    label={<div className="text-[14px] font-medium">Họ</div>}
                    rules={[
                      { required: true, message: "Họ không được để trống" },
                    ]}
                    className="mb-3"
                  >
                    <Input
                      placeholder="NGUYEN"
                      onChange={(e) => handleInputChange(e, "surnameContact")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <div className="text-[14px] font-medium">
                        Tên đệm & Tên
                      </div>
                    }
                    name="lastNameContact"
                    rules={[
                      {
                        required: true,
                        message: "Tên đệm & Tên không được để trống",
                      },
                    ]}
                    className="mb-3"
                  >
                    <Input
                      placeholder="VAN A"
                      onChange={(e) => handleInputChange(e, "lastNameContact")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="mb-3">
                <Col span={12}>
                  <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Số điện thoại không được để trống",
                      },
                      {
                        pattern: /^(03|05|07|08|09)\d{8}$|^\+84\d{9}$/,
                        message: "Số điện thoại không hợp lệ",
                      },
                    ]}
                    className="mb-3"
                  >
                    <Input placeholder="Nhập số điện thoại..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Email không được để trống",
                      },
                      {
                        type: "email",
                        message: "Vui lòng nhập email đúng định dạng",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập Email..." />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Ghi chú đơn hàng" name="note" className="mb-3">
                <TextArea placeholder="Ghi chú đơn hàng..." rows={2} />
              </Form.Item>
              <Form.Item
                label=""
                name="invoice"
                className="mb-3"
                valuePropName="checked"
              >
                <Checkbox>Xuất hoá đơn</Checkbox>
              </Form.Item>
              <div className="text-[14px] text-[#D72F20] mb-3">
                Quý khách chỉ có thể yêu cầu xuất hoá đơn trong vòng 5 ngày kể
                từ ngày xuất vé
              </div>
              {invoice && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="taxCode"
                      className="mb-3"
                      label={
                        <div className="text-[14px]">Mã số thuế (VAT)</div>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Mã số thuế không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Mã số thuế..." />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div className="text-[14px]">Người nhận hoá đơn</div>
                      }
                      name="receiverReceive"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Người nhận hoá đơn không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập người nhận hoá đơn..." />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
            <div
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              className=" bg-white p-4 text-[#0D1634] rounded-[20px] mb-3"
            >
              <div className="text-[#0D1634] text-[14px] font-medium mb-3">
                Mã giảm giá
              </div>
              <Search
                placeholder="Nhập mã giảm giá"
                enterButton={
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#0064D2",
                      borderColor: "#0064D2",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    Áp dụng
                  </Button>
                }
                allowClear
                onChange={(e) => {
                  if (!e.target.value) {
                    setVoucherTxt("");
                    setVoucherValue(0);
                  }
                }}
                onSearch={applyVoucher}
              />
              {voucherTxt && (
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: voucherTxt }}
                />
              )}
            </div>
          </Form>
          <Collapse
            bordered={false}
            expandIcon={null}
            defaultActiveKey={["1"]}
            className="bg-white"
            onChange={(e) => {
              setShowPriceDetail(e.length > 0);
            }}
            items={[
              {
                key: "1",
                showArrow: false,
                label: (
                  <>
                    {processPriceDone && (
                      <div className="flex justify-between">
                        <span className="text-[14px] font-medium">
                          Tổng cộng (Thuế + Phí):
                        </span>
                        <div className="flex flex-col">
                          <span className="text-[16px] font-bold text-[#D72F20]">
                            {totalListPrice.totalDisplay.toLocaleString(
                              "vi-VN"
                            )}{" "}
                            ₫
                          </span>
                          <span className="flex items-center">
                            <span className="text-[12px] text-[#378ADD]">
                              {showPriceDetail ? "Thu gọn" : "Chi tiết"}{" "}
                            </span>
                            <ForwardOutlined
                              style={{
                                color: "#378ADD",
                                fontSize: 18,
                              }}
                              rotate={showPriceDetail ? 90 : -90}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ),
                children: (
                  <>
                    {processPriceDone && (
                      <>
                        {chooseFlight.length === 2 && (
                          <div className="text-[12px] mb-2 font-medium">
                            Chiều đi
                          </div>
                        )}
                        <ul className="list-none">
                          {["Adt", "Chd", "Inf"].map((type) => (
                            <>
                              {chooseFlight[0][type] > 0 && (
                                <li
                                  key={type}
                                  className="flex justify-between mb-2 text-[12px] text-[#5F5F67]"
                                >
                                  <span className="w-2/6">
                                    {type === "Adt"
                                      ? "Vé người lớn"
                                      : type === "Chd"
                                        ? "Vé trẻ em"
                                        : "Vé em bé"}
                                    :
                                  </span>
                                  <span className="w-2/6 text-right">
                                    {chooseFlight[0][type]} x{" "}
                                    {chooseFlight[0][
                                      `Fare${[type]}New`
                                    ].toLocaleString("vi-VN")}
                                    đ
                                  </span>
                                  <span className="w-2/6 text-right">
                                    ={" "}
                                    {chooseFlight[0][
                                      `TotalFare${type}New`
                                    ].toLocaleString("vi-VN")}{" "}
                                    đ
                                  </span>
                                </li>
                              )}
                            </>
                          ))}
                          {chooseFlight[0].TotalTax > 0 && (
                            <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                              <span className="w-2/6">Thuế</span>
                              <span className="w-2/6 text-right"></span>
                              <span className="w-2/6 text-right">
                                ={" "}
                                {chooseFlight[0].TotalTax.toLocaleString(
                                  "vi-VN"
                                )}{" "}
                                đ
                              </span>
                            </li>
                          )}
                          {chooseFlight[0].TotalServiceFee > 0 && (
                            <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                              <span className="w-2/6">Phí dịch vụ</span>
                              <span className="w-2/6 text-right"></span>
                              <span className="w-2/6 text-right">
                                ={" "}
                                {chooseFlight[0].TotalServiceFee.toLocaleString(
                                  "vi-VN"
                                )}{" "}
                                đ
                              </span>
                            </li>
                          )}
                          {totalListPrice.baggage > 0 && (
                            <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                              <span className="w-2/6">Mua hành lý:</span>
                              <span className="w-2/6 text-right"></span>
                              <span className="w-2/6 text-right">
                                {" "}
                                {totalListPrice.baggage.toLocaleString("vi-VN")}
                                đ
                              </span>
                            </li>
                          )}
                        </ul>
                        {chooseFlight.length === 2 && (
                          <>
                            <div className="text-[12px] mb-2 font-medium">
                              Chiều về
                            </div>
                            <ul className="list-none">
                              {["Adt", "Chd", "Inf"].map((type) => (
                                <>
                                  {chooseFlight[1][type] > 0 && (
                                    <li
                                      key={type}
                                      className="flex justify-between mb-2 text-[12px] text-[#5F5F67]"
                                    >
                                      <span className="w-2/6">
                                        {chooseFlight[1][type] > 0 &&
                                          (type === "Adt"
                                            ? "Vé người lớn"
                                            : type === "Chd"
                                              ? "Vé trẻ em"
                                              : "Vé em bé")}
                                        :
                                      </span>
                                      <span className="w-2/6 text-right">
                                        {chooseFlight[1][type]} x{" "}
                                        {chooseFlight[1][
                                          `Fare${[type]}New`
                                        ].toLocaleString("vi-VN")}
                                        đ
                                      </span>
                                      <span className="w-2/6 text-right">
                                        ={" "}
                                        {chooseFlight[1][
                                          `TotalFare${type}New`
                                        ].toLocaleString("vi-VN")}{" "}
                                        đ
                                      </span>
                                    </li>
                                  )}
                                </>
                              ))}
                              {chooseFlight[1].TotalTax > 0 && (
                                <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                                  <span className="w-2/6">Thuế</span>
                                  <span className="w-2/6 text-right"></span>
                                  <span className="w-2/6 text-right">
                                    ={" "}
                                    {chooseFlight[1].TotalTax.toLocaleString(
                                      "vi-VN"
                                    )}{" "}
                                    đ
                                  </span>
                                </li>
                              )}
                              {chooseFlight[1].TotalServiceFee > 0 && (
                                <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                                  <span className="w-2/6">Phí dịch vụ</span>
                                  <span className="w-2/6 text-right"></span>
                                  <span className="w-2/6 text-right">
                                    ={" "}
                                    {chooseFlight[1].TotalServiceFee.toLocaleString(
                                      "vi-VN"
                                    )}{" "}
                                    đ
                                  </span>
                                </li>
                              )}
                              {totalListPrice.baggage_out > 0 && (
                                <li className="flex justify-between mb-2 text-[12px] text-[#5F5F67]">
                                  <span className="w-2/6">Mua hành lý:</span>
                                  <span className="w-2/6 text-right"></span>
                                  <span className="w-2/6 text-right">
                                    {totalListPrice.baggage_out.toLocaleString(
                                      "vi-VN"
                                    )}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </>
                        )}
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div className="fixed bottom-0 w-full">
        <div className="flex justify-between bg-white p-4 w-full">
          <Button
            onClick={() => {
              setStep((step) => step - 1);
            }}
            className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#0064D2] text-[15px] border-[#0064D2]"
          >
            <span className="font-semibold">Quay lại</span>
          </Button>
          <Button
            type="primary"
            onClick={() => {
              formRef.current.submit();
            }}
            className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-[#0064D2] text-white text-[15px]"
          >
            <span className="font-semibold">Tiếp tục</span>
          </Button>
        </div>
        {/* <hr />
        <div className="flex bg-white h-[81px] w-full px-[24px] py-3 justify-between items-center">
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
            <a href="/ve-may-bay" className="text-[#22313F] text-[14px] font-medium">
              Vé máy bay
            </a>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Khách sạn</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé tàu</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé xe</span>
          </div>
        </div> */}
      </div>

      {contextHolder}
    </div>
  );
};

export default Step3;
