import React from "react";
import { Form, Input, Button } from "antd";

const CustomInputNumber = ({
  name,
  label,
  min = 0,
  max = 9,
  defaultValue = 1,
  form,
}) => {
  return (
    <div className="flex flex-col items-center">
      {label}
      <div
        className="flex items-center  px-2 py-1 rounded-[20px] w-10/12 max-w-[110px]"
        style={{ border: "1px solid #0064D2" }}
      >
        <Button
          className="mr-1 border-0 p-0 "
          style={{ width: "10px", height: "10px" }}
          onClick={() => {
            const currentVal = form.getFieldValue(name);
            if (currentVal > min) {
              form.setFieldsValue({
                [name]: currentVal - 1,
              });
            }
          }}
        >
          <img src={"/images/minus.svg"} alt="" />
        </Button>

        <Form.Item
          className="mb-0 grow"
          name={name}
          initialValue={defaultValue}
        >
          <Input
            type="number"
            min={min}
            max={max}
            className="text-center text-[16px]"
            value={form.getFieldValue(name)}
            variant="borderless"
          />
        </Form.Item>

        <Button
          className="ml-1 border-0 p-0 "
          style={{ width: "10px", height: "10px" }}
          onClick={() => {
            const currentVal = form.getFieldValue(name);
            if (currentVal < max) {
              form.setFieldsValue({
                [name]: currentVal + 1,
              });
            }
          }}
        >
          <img src={"/images/plus.svg"} alt="" />
        </Button>
      </div>
    </div>
  );
};

export default CustomInputNumber;
