import { Col, Drawer, Row, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import {
  calculateTimeDifference,
  formatNumberWithDots,
} from "../../helpers/utils";
const { Text, Title } = Typography;

const TicketDetailsDrawer = ({ visible, onClose, ticketDetails, airports }) => {
  const ticketSummary = (ticketDetails?.transaction_ticket || []).reduce(
    (summary, item) => {
      const passengerType = item.passenger_type;
      const totalAmount = item.amount_total || 0;
      const ticketType = item.type;

      if (ticketType === "depart") {
        if (passengerType === "ADT") {
          summary.depart.AdtCount += 1;
          summary.depart.AdtTotal += totalAmount;
        } else if (passengerType === "CHD") {
          summary.depart.ChdCount += 1;
          summary.depart.ChdTotal += totalAmount;
        } else if (passengerType === "INF") {
          summary.depart.InfCount += 1;
          summary.depart.InfTotal += totalAmount;
        }
      } else if (ticketType === "return") {
        if (passengerType === "ADT") {
          summary.return.AdtCount += 1;
          summary.return.AdtTotal += totalAmount;
        } else if (passengerType === "CHD") {
          summary.return.ChdCount += 1;
          summary.return.ChdTotal += totalAmount;
        } else if (passengerType === "INF") {
          summary.return.InfCount += 1;
          summary.return.InfTotal += totalAmount;
        }
      }

      return summary;
    },
    {
      depart: {
        AdtCount: 0,
        AdtTotal: 0,
        ChdCount: 0,
        ChdTotal: 0,
        InfCount: 0,
        InfTotal: 0,
      },
      return: {
        AdtCount: 0,
        AdtTotal: 0,
        ChdCount: 0,
        ChdTotal: 0,
        InfCount: 0,
        InfTotal: 0,
      },
    }
  );
  // Hàm tính tổng cho mỗi lượt vé
  const calculateTotal = (ticketTypeSummary) => {
    return (
      ticketTypeSummary.AdtTotal +
      ticketTypeSummary.ChdTotal +
      ticketTypeSummary.InfTotal
    );
  };

  // Tính tổng cho vé đi và vé về
  const departTotal = calculateTotal(ticketSummary.depart);
  const returnTotal = calculateTotal(ticketSummary.return);
  return (
    <Drawer placement="bottom" onClose={onClose} open={visible} height={600}>
      <Title level={4}>
        {
          (airports || []).find(
            (item) =>
              item.code === ticketDetails?.transaction_fare?.[0]?.start_point ||
              ""
          )?.city_name_vi
        }{" "}
        -{" "}
        {
          (airports || []).find(
            (item) =>
              item.code === ticketDetails?.transaction_fare?.[0]?.end_point ||
              ""
          )?.city_name_vi
        }{" "}
      </Title>
      <div className="flex flex-col gap-4">
        {ticketDetails?.transaction_fare?.map((item, index) => (
          <div key={index}>
            <div
              className="p-4 rounded-[8px] flex flex-col gap-4"
              style={{
                border: "1px solid #EBEBF0",
              }}
            >
              <div className="flex gap-4 items-center">
                <img
                  src={`https://abtrip.vn/images/airlines/${(
                    item.airline || ""
                  ).toLowerCase()}.gif`}
                  alt="logo"
                  style={{ width: "auto", height: 30 }}
                />
                <div className="flex flex-col justify-between">
                  {" "}
                  <Text className="font-bold text-[14px]" strong>
                    {item.airline} - {item.group_class}
                  </Text>
                  <Text className="text-[14px] text-[#808089]">
                    {item.flight_number}
                  </Text>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #EBEBF0",
                }}
              />
              <Row gutter={16} align={"middle"}>
                <Col span={8}>
                  <h4 className="font-bold text-sm">
                    {item?.start_point || ""}
                  </h4>
                  <p className="text-xs">
                    {(item?.start_at || "").split(" ")[1]}
                  </p>
                  <p className="text-xs">
                    {dayjs(item?.start_at).format("DD/MM/YYYY")}
                  </p>
                </Col>
                <Col span={8} className="justify-center text-center">
                  <p className="text-xs text-[#808089]">
                    {calculateTimeDifference(item?.start_at, item?.end_at)}
                  </p>
                  <img src="/images/flight-line.svg" alt="" />
                </Col>
                <Col span={8}>
                  <h4 className="font-bold text-sm">{item?.end_point || ""}</h4>
                  <p className="text-xs">
                    {(item?.end_at || "").split(" ")[1]}
                  </p>
                  <p className="text-xs">
                    {dayjs(item?.end_at).format("DD/MM/YYYY")}
                  </p>
                </Col>
              </Row>
            </div>
            {index === 0 ? (
              <div className="flex flex-col gap-2">
                <Row justify="space-between mt-4">
                  <Text>{ticketSummary.depart.AdtCount} Người lớn</Text>
                  <Text>
                    {formatNumberWithDots(ticketSummary.depart.AdtTotal)} VND
                  </Text>
                </Row>
                <Row justify="space-between">
                  <Text>{ticketSummary.depart.ChdCount} Trẻ em</Text>
                  <Text>
                    {formatNumberWithDots(ticketSummary.depart.ChdTotal)} VND
                  </Text>
                </Row>
                <Row justify="space-between">
                  <Text>{ticketSummary.depart.InfCount} Em bé</Text>
                  <Text>
                    {formatNumberWithDots(ticketSummary.depart.InfTotal)} VND
                  </Text>
                </Row>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <Row justify="space-between mt-4">
                  <Text>{ticketSummary.return.AdtCount} Người lớn</Text>
                  <Text>
                    {formatNumberWithDots(ticketSummary.return.AdtTotal)} VND
                  </Text>
                </Row>
                <Row justify="space-between">
                  <Text>{ticketSummary.return.ChdCount} Trẻ em</Text>
                  <Text>
                    {formatNumberWithDots(ticketSummary.return.ChdTotal)} VND
                  </Text>
                </Row>
                <Row justify="space-between">
                  <Text>{ticketSummary.return.InfCount} Em bé</Text>
                  <Text>
                    {formatNumberWithDots(ticketSummary.return.InfTotal)} VND
                  </Text>
                </Row>
              </div>
            )}
            <div className="flex flex-col gap-2 mt-2">
              <Row justify="space-between">
                <Text>Thuế</Text>
                <Text>Đã bao gồm</Text>
              </Row>
              {/* <Row justify="space-between">
                <Text>Hành lý</Text>
                <Text>{data[0]?.hand_baggage || "N/A"}</Text>
              </Row> */}
              <Row justify="space-between">
                <Text>Thủ tục ưu tiên</Text>
                <Text>0 VND</Text>
              </Row>
              <Row justify="space-between">
                <Text>Giảm giá</Text>
                <Text style={{ color: "green" }}>
                  {formatNumberWithDots(ticketDetails?.amount_discount)} VND
                </Text>
              </Row>
              <Row justify="space-between">
                <Text strong>Tổng số tiền</Text>
                <Text strong style={{ fontSize: "18px", color: "blue" }}>
                  {formatNumberWithDots(
                    index === 0
                      ? calculateTotal(ticketSummary.depart)
                      : calculateTotal(ticketSummary.return)
                  )}{" "}
                  VND
                </Text>
              </Row>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default TicketDetailsDrawer;
