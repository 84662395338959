import moment from "moment";

export const dateFilterAir = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format("DDMMYYYY");
};

export const formatTime = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format("HH:mm");
};

export const formatDateNoTime = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format("DD/MM/YYYY");
};

export const convertFormatDateNoTime = (date) => {
  const dateConvert = moment(date, "DDMMYYYY");
  if (!date) {
    return null;
  }

  return moment(dateConvert).format("DD/MM/YYYY");
};
