export default function LoadingAir() {
  return (
    <div className="bg-white">
      <div className="h-1 bg-white w-screen relative">
        <div className="h-1 bg-[#F6958C] absolute waiting-bg-air"></div>
        <img className="absolute top-[-10px] waiting-air" src="/images/air.svg" alt=""/>
      </div>
    </div>
  );
}


