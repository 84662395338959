import React, { useState, useEffect } from "react";
import BgSuccess from "../../images/bg-success.svg";
import { Button, notification } from "antd";
import _ from "lodash";
import $axios from "../../services/axios";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const Step6 = ({ onChangeStep, statusPayment, orderId, setLoading }) => {
  const location = useLocation();
  const history = useHistory();
  const [messsage, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  let bookingInfo = sessionStorage.getItem("bookingInfo")
    ? JSON.parse(sessionStorage.getItem("bookingInfo"))
    : {};
  const queryParams = new URLSearchParams(location.search);
  const amount = queryParams.get("amount");
  const payment_type = queryParams.get("payment_type");
  const reference_number = queryParams.get("reference_number");
  const status = queryParams.get("status");
  const trans_ref_no = queryParams.get("trans_ref_no");
  const website_id = queryParams.get("website_id");
  const signature = queryParams.get("signature");
  const messageVtc = queryParams.get("message");
  const payment_result = {
    amount: amount,
    message: messageVtc,
    payment_type: payment_type,
    reference_number: reference_number,
    status: Number(status),
    trans_ref_no: trans_ref_no,
    website_id: website_id,
    signature: signature,
  };
  useEffect(() => {
    if (statusPayment) {
      bookingInfo.bodyTransaction.transaction = {
        ...bookingInfo.bodyTransaction.transaction,
        payment_at: moment().format("YYYY-MM-DD hh:mm:ss"),
        payment_partner: payment_type,
        payment_transaction: trans_ref_no,
        payment_method: payment_type,
        payment_status: Number(status),
      };
      sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
      if (bookingInfo.isPromotionOrSameDayTicket) {
        handleBookingPromo();
      } else {
        handleBookingNormal();
      }
    }
  }, [statusPayment]);

  const handleBookingPromo = async () => {
    setLoading(true);
    try {
      const canBooking = await verifyFlight(bookingInfo.chooseFlight);
      if (canBooking) {
        setLoading(true);
        try {
          const res = await $axios.$post(
            "/flights/bookflight",
            bookingInfo.bodyBooking
          );
          if (res.data.ErrorCode === "000") {
            bookingInfo = {
              ...bookingInfo,
              ...res.data,
              OrderIDMapping: res.data.OrderID.replace("ABT", "IPASS"),
            };
            updateTransactionVmb(bookingInfo, true);
            setTimeout(async () => {
              handleBookingNormal(res.data.OrderID);
            }, 0);
          } else {
            updateTransactionVmb(bookingInfo, false);
            setMessage(
              "Có lỗi xảy ra trong quá trình đặt vé. Vui lòng liên hệ tổng đài hỗ trợ"
            );
            notification.error({
              status: "error",
              message: "Thất bại",
              description:
                "Có lỗi xảy ra trong quá trình đặt vé. Vui lòng liên hệ tổng đài hỗ trợ!",
            });
          }
        } catch (error) {
          updateTransactionVmb(bookingInfo, false);
          setMessage(
            "Có lỗi xảy ra trong quá trình đặt vé. Vui lòng liên hệ tổng đài hỗ trợ"
          );
          notification.error({
            status: "error",
            message: "Thất bại",
            description:
              "Có lỗi xảy ra trong quá trình đặt vé. Vui lòng liên hệ tổng đài hỗ trợ!",
          });
        } finally {
          setLoading(false);
          setTimeout(() => {
            sessionStorage.removeItem("bookingInfo");
            const { pathname } = location;
            history.replace(pathname);
          }, 2000);
        }
        // gọi api booking => xuất vé => update giao dịch
      } else {
        try {
          await updateTransactionVmb(bookingInfo, false);
          sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
          setMessage(
            "Có lỗi xảy ra trong quá trình đặt vé. Vui lòng liên hệ tổng đài hỗ trợ"
          );
          notification.error({
            status: "error",
            message: "Thất bại",
            description:
              "Có lỗi xảy ra trong quá trình đặt vé. Vui lòng liên hệ tổng đài hỗ trợ!",
          });
        } catch (error) {
        } finally {
          setTimeout(() => {
            sessionStorage.removeItem("bookingInfo");
            const { pathname } = location;
            history.replace(pathname);
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const updateTransactionVmb = async (bookingInfo, bookingABTripSucess) => {
    if (!bookingABTripSucess) {
      const bodyUpdateTransction = {
        token: bookingInfo.bodyTransaction.token,
        transaction: {
          ...bookingInfo.bodyTransaction.transaction,
          booking_status: -2,
        },
        orderDetail: bookingInfo.bodyTransaction.orderDetail.map((order) => {
          order.booking_status = -2;
          return order;
        }),
        transactionFare: bookingInfo.bodyTransaction.transactionFare.map(
          (item) => {
            item.booking_status = -2;
            return item;
          }
        ),
        payment_result,
      };

      try {
        await $axios.$post("/update", bodyUpdateTransction);
        bookingInfo.bodyTransaction = bodyUpdateTransction;
        sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
      } catch (error) {
        notification.error({
          status: "error",
          message: "Thất bại",
          description: "Đã có lỗi xảy ra!",
        });
      } finally {
      }
      return;
    }
    let _orderDetail = _.cloneDeep(bookingInfo.bodyTransaction.orderDetail);
    _orderDetail.map((item, index) => {
      let BookingCode = "";
      let ExpiryDate = "";
      // chuyến 1 chiều, hoặc chuyến 2 chiều cùng mã máy bay
      if (
        bookingInfo.chooseFlight.length === 1 ||
        (bookingInfo.chooseFlight.length === 2 &&
          bookingInfo.ListBooking.length === 1)
      ) {
        BookingCode = bookingInfo.ListBooking[0].BookingCode;
        ExpiryDate = bookingInfo.ListBooking[0].ExpiryDate;
        // chuyến 2 chiều khác mã máy bay
      } else {
        // get booking code chiều đi
        BookingCode = bookingInfo.ListBooking[index].BookingCode;
        ExpiryDate = bookingInfo.ListBooking[0].ExpiryDate;
      }
      item.order_code = bookingInfo.OrderID;
      item.order_code_mapping = bookingInfo.OrderIDMapping;
      item.booking_code = BookingCode;
      item.expired_at = moment(ExpiryDate).format("YYYY-MM-DD HH:mm:ss");
      item.booking_at = moment(ExpiryDate).format("YYYY-MM-DD HH:mm:ss");
      item.purchase_at = moment(ExpiryDate).format("YYYY-MM-DD HH:mm:ss");
      item.booking_status = 2;
      return item;
    });
    let transactionFare = _.cloneDeep(
      bookingInfo.bodyTransaction.transactionFare
    );
    transactionFare.map((trans) => {
      const orderByTrans = _orderDetail.find(
        (order) => order.type_flight === trans.type_flight
      );
      trans.order_code = orderByTrans.order_code;
      trans.order_code_mapping = orderByTrans.order_code_mapping;
      trans.booking_code = orderByTrans.booking_code;
      trans.expired_at = orderByTrans.expired_at;
      trans.booking_at = orderByTrans.booking_at;
      trans.purchase_at = orderByTrans.purchase_at;
      trans.booking_status = orderByTrans.booking_status;
      delete trans.created_at;
      return trans;
    });
    const bodyUpdateTransction = {
      token: bookingInfo.bodyTransaction.token,
      transaction: {
        ...bookingInfo.bodyTransaction.transaction,
        order_code: bookingInfo.OrderID,
        order_code_mapping: bookingInfo.OrderIDMapping,
        order_at: moment().format("YYYY-MM-DD HH:mm:ss"), //ngày đặt
        amount_original: bookingInfo.TotalPrice,
        booking_status: 2,
      },
      orderDetail: _orderDetail,
      transactionFare,
    };
    try {
      await $axios.$post("/update", bodyUpdateTransction);
      bookingInfo.bodyTransaction = bodyUpdateTransction;
      sessionStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
    }
  };

  const verifyFlight = async (chooseFlight) => {
    setLoading(true);
    try {
      const body = {
        ListFareData: _.map(chooseFlight, (f) => {
          return {
            Session: f.Session,
            FareDataId: f.FareDataId,
            ListFlight: f.ListFlight.map((f2) => {
              return {
                FlightValue: f2.FlightValue,
              };
            }),
          };
        }),
      };
      const res = await $axios.$post("/flights/verifyflight", body);
      if (res.data.ErrorCode === "000") {
        const totalNetPriceOrigin =
          _.get(chooseFlight, "[0].TotalNetPrice", 0) +
          _.get(chooseFlight, "[1].TotalNetPrice", 0);
        const totalNetPriceCompare =
          _.get(res.data, "ListFareStatus[0].FareData.TotalNetPrice", 0) +
          _.get(res.data, "ListFareStatus[1].FareData.TotalNetPrice", 0);
        return totalNetPriceOrigin === totalNetPriceCompare;
      } else {
        notification.error({
          status: "error",
          message: "Thất bại",
          description: res.data.Message,
        });
        return false;
      }
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };
  const handleBookingNormal = async (orderIdPromo) => {
    setLoading(true);
    try {
      const body = {
        OrderID: orderIdPromo ? orderIdPromo : orderId,
      };
      const res = await $axios.$post("/flights/issue", body);
      let orderDetail = _.cloneDeep(bookingInfo.bodyTransaction.orderDetail);
      orderDetail = _.map(orderDetail, (order, index) => {
        order.booking_status = 3;
        return order;
      });
      let transactionFare = _.cloneDeep(
        bookingInfo.bodyTransaction.transactionFare
      );
      transactionFare = _.map(transactionFare, (transc, index) => {
        transc.booking_status = 3;
        return transc;
      });
      let list_ticket = [];
      const resTicket = Object.values(res.data.Data) || [];
      if (resTicket.length > 0) {
        _.forEach(resTicket, (item, index) => {
          if (item.ListTicket.length > 0) {
            _.forEach(item.ListTicket, (ticket) => {
              list_ticket.push({
                order_code: bookingInfo.bodyTransaction.transaction.order_code,
                booking_code: ticket.BookingCode, //mã booking
                ticket_number: ticket?.TicketNumber, //mã vé
                ticket_type: ticket?.TicketType, //loại vé: thương gia, ..
                passenger_name: ticket?.PassengerName, //tên người đặt vé
                passenger_gender: ticket?.Gender ? "male" : "female", //giới tính
                passenger_type: ticket?.PassengerType, //hạng vé:  ADT - người lớn , CHD - trẻ em
                amount_total: ticket?.TotalPrice, //tổng tiến vé
                amount_vat: ticket?.Vat, //tiền vat
                amount_fee: ticket?.Fee, //phụ phí
                amount_fare: ticket?.Fare, //giá vé
                amount_tax: ticket?.Tax, //tiền thuế
                type: index === 0 ? "depart" : "return",
              });
            });
          }
        });
      }
      const bodyUpdate = {
        token: bookingInfo.bodyTransaction.token,
        transaction: {
          ...bookingInfo.bodyTransaction.transaction,
          booking_status: 3,
        },
        orderDetail,
        list_ticket,
        payment_result,
        transactionFare,
      };
      await $axios.$post("/update", bodyUpdate);
      setMessage(res.data.Message);
    } catch (error) {
      console.log(error, "error");
      const bodyUpdate = {
        token: bookingInfo.bodyTransaction.token,
        transaction: {
          ...bookingInfo.bodyTransaction.transaction,
          booking_status: -3,
        },
        orderDetail: bookingInfo.bodyTransaction.orderDetail.map((order) => {
          order.booking_status = -3;
          return order;
        }),
        transactionFare: bookingInfo.bodyTransaction.transactionFare.map(
          (item) => {
            item.booking_status = -3;
            return item;
          }
        ),
        payment_result,
      };
      await $axios.$post("/update", bodyUpdate);
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        sessionStorage.removeItem("bookingInfo");
        const { pathname } = location;
        history.replace(pathname);
      }, 2000);
    }
  };

  return (
    <div className="bg-[#F6F6F6] mb-4 pb-[100px]">
      <div>
        <div className="w-full relative">
          <img src={BgSuccess} alt="" className="w-full" />
          <div className="flex flex-col w-[70%] absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-4/5 justify-center gap-4">
            {statusPayment ? (
              <div className="text-[20px] text-[#00AB56] font-bold text-center">
                Thanh toán thành công
              </div>
            ) : (
              <div className="text-[20px] text-[#D72F20] font-bold text-center">
                Thanh toán thất bại
              </div>
            )}
            <div className="text-center text-[14px] text-[#27272A]">
              Tham khảo hàng trăm chuyến bay khác trên IPass ngay bây giờ!
            </div>
          </div>
        </div>
        {messsage && showMessage && (
          <div className="p-6" dangerouslySetInnerHTML={{ __html: messsage }} />
        )}
        <div className="w-full mt-4 px-4">
          <div className="flex justify-between">
            <Button
              onClick={() => {
                window.location.href = "/";
              }}
              className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#0064D2] border-[#0064D2] text-[15px]"
            >
              <span className="font-semibold">Trang chủ</span>
            </Button>
            {messsage && !showMessage && (
              <Button
                onClick={() => setShowMessage(true)}
                className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-[#0064D2] text-white text-[15px]"
              >
                <span className="font-semibold">Chi tiết vé</span>
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex bg-white h-[81px] w-full px-[24px] py-3 fixed bottom-0 justify-between items-center">
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
          <a href="/ve-may-bay" className="text-[#22313F] text-[14px] font-medium">
            Vé máy bay
          </a>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">Khách sạn</span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">Vé tàu</span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">Vé xe</span>
        </div>
      </div> */}
    </div>
  );
};

export default Step6;
